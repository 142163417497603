var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-card",
            {
              attrs: { color: "grey lighten-4", elevation: "0", width: "100%" },
            },
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _c("img", {
                  staticClass: "mt-2 mb-1",
                  attrs: {
                    src: require("@/assets/images/note_alt.svg"),
                    role: "img",
                    alt: "note logo",
                  },
                }),
              ]),
              _c(
                "v-row",
                {
                  staticClass: "caption text--darken-4",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mb-2 grey--text text--darken-4 text-subtitle-2",
                      class: _vm.notes.length > 0 ? "mb-7" : "mb-2",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.notes.length == 0 ? _vm.$t("noNotesMsg") : ""
                        )
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3 text-none rounded-lg",
                      attrs: { color: "primary", outlined: "" },
                      on: { click: _vm.openDialog },
                    },
                    [_vm._v(_vm._s(_vm.$t("addNote")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "div",
            { staticClass: "dropbox ml-3 mr-3" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("img", {
                    staticClass: "px-4 mt-2",
                    attrs: { src: require("@/assets/images/note_alt.svg") },
                  }),
                  _c("v-col", { staticClass: "mt-2", attrs: { cols: "9" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-subtitle-2 font-weight-medium grey--text text--darken-4",
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("attachNotes"))),
                        _c("br"),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-regular grey--text text--darken-2",
                            staticStyle: { "font-size": "10px" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.notes.length == 0
                                  ? _vm.$t("noNotesMsg")
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2 ml-16",
                      class: _vm.notes.length > 0 ? "mt-3" : "mt-2",
                      attrs: { cols: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 mb-3 text-none rounded-sm",
                          attrs: { color: "primary", outlined: "" },
                          on: { click: _vm.openDialog },
                        },
                        [_vm._v(_vm._s(_vm.$t("addNote")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c(
        "div",
        [
          _vm.notes.length > 0
            ? _c(
                "v-card",
                { attrs: { elevation: "0", width: "100%" } },
                [
                  _c(
                    "v-container",
                    [
                      _c("v-row", { staticClass: "ml-1" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mt-1 grey--text text--darken-4 text-subtitle-2",
                          },
                          [_vm._v(_vm._s(_vm.$t("attachedNotes")))]
                        ),
                      ]),
                      _c("v-row", { staticClass: "ml-1" }, [
                        _c(
                          "span",
                          { staticClass: "caption grey--text text--darken-2" },
                          [
                            _vm._v(
                              _vm._s(_vm.notes.length + " " + _vm.$t("smNotes"))
                            ),
                          ]
                        ),
                      ]),
                      _vm._l(_vm.notes, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "mt-3" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "2",
                                      lg: "1",
                                      md: "1",
                                      xl: "1",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/note_rows_icon.svg"),
                                        role: "img",
                                        alt: "note logo",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-0",
                                    attrs: {
                                      cols: "8",
                                      lg: "10",
                                      md: "10",
                                      xl: "10",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "pt-3 caption text--black text--darken-4",
                                        class: _vm.$vuetify.breakpoint.smAndDown
                                          ? "pl-2"
                                          : "",
                                      },
                                      [_vm._v(" " + _vm._s(item.note) + " ")]
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "pt-1 pb-4 caption grey--text grey-lighten-1",
                                        class: _vm.$vuetify.breakpoint.smAndDown
                                          ? "pl-2"
                                          : "",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .moment(item.created_at)
                                              .format("MM/DD/YYYY") +
                                              " by " +
                                              _vm.userMap[item.user_id].name
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "2",
                                      lg: "1",
                                      md: "1",
                                      xl: "1",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        class: _vm.$vuetify.breakpoint.smAndDown
                                          ? "pr-2 float-right"
                                          : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteNote(i)
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-delete-outline")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "420",
                "max-height": "400",
                persistent: "",
              },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "overflow-hidden" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 ml-4" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                        },
                        [_vm._v(_vm._s(_vm.$t("addNotes")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-2 my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1),
                  _c("v-row", { staticClass: "ma-0 ml-4 pt-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text--darken-4 text-subtitle-1 font-weight-regular",
                      },
                      [_vm._v("Note")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 ml-4 mr-4 pt-3" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          rows: "3",
                          maxlength: "4000",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          required: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(" *"),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("enterNote")) + " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3342964642
                        ),
                        model: {
                          value: _vm.note,
                          callback: function ($$v) {
                            _vm.note = $$v
                          },
                          expression: "note",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled:
                              !_vm.note ||
                              _vm.note == "" ||
                              _vm.addButtonClicked,
                          },
                          on: { click: _vm.addNote },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }