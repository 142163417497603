var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "grey lighten-5", elevation: "0", width: "100%" } },
    [
      _c(
        "v-row",
        { staticClass: "pl-2 pt-2", attrs: { "no-gutters": "" } },
        [
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    varient: "plain",
                    disabled: _vm.limitExceeds,
                  },
                  on: { click: _vm.showUploadDialog },
                },
                [_c("v-icon", [_vm._v("mdi-upload")]), _vm._v(" Upload ")],
                1
              )
            : _vm._e(),
          _vm.uploadError
            ? _c(
                "v-alert",
                {
                  staticClass: "caption font-weight-regular ml-2 py-2 px-5",
                  attrs: {
                    type: "warning",
                    color: "#AD4C0B",
                    icon: "mdi-alert-outline",
                    text: "",
                    height: "38px",
                    dismissible: "",
                  },
                },
                [_vm._v(" Some error occured while uploading files. ")]
              )
            : _vm._e(),
          _vm.limitExceeds
            ? _c(
                "v-alert",
                {
                  staticClass: "caption font-weight-regular ml-2 py-2 px-5",
                  attrs: {
                    type: "error",
                    color: "#AD4C0B",
                    icon: "mdi-alert-outline",
                    text: "",
                    height: "38px",
                  },
                },
                [_vm._v(" Storage limit reached ")]
              )
            : _vm._e(),
          _c("v-spacer"),
          !_vm.$vuetify.breakpoint.smAndDown && _vm.uploadedFiles.length
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.selected.length === 0,
                    outlined: "",
                    varient: "plain",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDeleteDialog(false)
                    },
                  },
                },
                [_vm._v(" delete ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.uploadedFiles.length
        ? _c(
            "div",
            { staticClass: "pa-2" },
            [
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { width: "100%" },
                    attrs: {
                      headers: _vm.dataTableHeaders,
                      items: _vm.uploadedFiles,
                      "hide-default-footer": "",
                      "show-select": "",
                      "checkbox-color": "primary",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.date",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatTicketDate(item.date)) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.view",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "view-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImageInDialog(item)
                                    },
                                  },
                                },
                                [_vm._v("mdi-eye")]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.edit",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editItem(item)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.delete",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "delete-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteDialog(item.id, item)
                                    },
                                  },
                                },
                                [_vm._v("mdi-delete")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  })
                : _c(
                    "div",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-2",
                          attrs: { align: "center", justify: "space-between" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Select All" },
                                on: { click: _vm.toggleSelectAll },
                                model: {
                                  value: _vm.selectAll,
                                  callback: function ($$v) {
                                    _vm.selectAll = $$v
                                  },
                                  expression: "selectAll",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    varient: "plain",
                                    disabled: _vm.selected.length === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteDialog(false)
                                    },
                                  },
                                },
                                [_vm._v(" Delete ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.uploadedFiles, function (item) {
                        return _c(
                          "div",
                          { key: item.id },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "my-2",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass:
                                      "grey lighten-4 text--darken-4",
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-2",
                                        attrs: { cols: "7" },
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass:
                                            "mt-0 my-custom-checkbox",
                                          attrs: {
                                            "hide-details": "",
                                            value: item,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.caption)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right pa-2",
                                        attrs: { cols: "5" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImageInDialog(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-eye")])],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-pencil"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDeleteDialog(
                                                  item.id,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "pa-4",
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-2",
                                        attrs: { cols: "4" },
                                      },
                                      [
                                        _c("div", { staticClass: "pb-2" }, [
                                          _vm._v("Date:"),
                                        ]),
                                        _c("div", { staticClass: "pb-2" }, [
                                          _vm._v("Comments:"),
                                        ]),
                                        _c("div", { staticClass: "pb-2" }, [
                                          _vm._v("User Name:"),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-2",
                                        attrs: { cols: "8" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold pb-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatTicketDate(
                                                    item.date
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold pb-2",
                                          },
                                          [_vm._v(_vm._s(item.comments))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold pb-2",
                                          },
                                          [_vm._v(_vm._s(item.userName))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "200px" },
            },
            [_vm._v(" There are no images attached to this ticket ")]
          ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
              model: {
                value: _vm.bottomNavbar,
                callback: function ($$v) {
                  _vm.bottomNavbar = $$v
                },
                expression: "bottomNavbar",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", variant: "plain", block: "" },
                      on: { click: _vm.showUploadDialog },
                    },
                    [
                      _vm._v(" Upload "),
                      _c("v-icon", { staticClass: "mr-0" }, [
                        _vm._v("mdi-upload"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "600",
            scrollable: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            {},
            [
              _c("v-card-title", [_vm._v(_vm._s(_vm.$t("uploadFiles")))]),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-0", staticStyle: { height: "400px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "file-dropzone ma-2",
                      on: {
                        dragover: _vm.handleDragOver,
                        dragleave: _vm.handleDragLeave,
                        drop: _vm.handleDrop,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "file-dropzone__content" },
                        [
                          _c("span", [_vm._v("Drag and drop files here")]),
                          _c(
                            "v-btn",
                            { on: { click: _vm.onClickChoseFiles } },
                            [_vm._v("Choose Files")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.files.length
                            ? _c(
                                "div",
                                { staticClass: "file-dropzone__selected" },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      headers: _vm.headers,
                                      items: _vm.fileItems,
                                      "hide-default-footer": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.actions",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "delete-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeFile(
                                                        item.file
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-delete")]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "item.size",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formattedFileSize(
                                                      item.file.size
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isError
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "caption font-weight-regular py-3 px-5",
                                  attrs: {
                                    type: "error",
                                    icon: "mdi-alert-outline",
                                    text: "",
                                    dismissible: "",
                                  },
                                },
                                [
                                  _vm.unsupportedFile
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold grey--text text--darken-4",
                                        },
                                        [_vm._v(_vm._s(_vm.unsupportedFile))]
                                      )
                                    : _vm._e(),
                                  _c("div", [_vm._v(_vm._s(_vm.fileErrorMsg))]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-4 mt-4",
                      class: _vm.captionError.length === 0 ? "mb-6" : "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          required: "",
                          "hide-details": _vm.captionError.length === 0,
                          "error-messages": _vm.captionError,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.caption.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.caption.$touch()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v(" *"),
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("caption")) + " "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.caption,
                          callback: function ($$v) {
                            _vm.caption = $$v
                          },
                          expression: "caption",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-4 mt-4",
                      class: _vm.commentError.length === 0 ? "mb-6" : "mb-0",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "auto-grow": "",
                          dense: "",
                          rows: "3",
                          "row-height": "25",
                          required: "",
                          "hide-details": _vm.commentError.length === 0,
                          "error-messages": _vm.commentError,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.comments.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.comments.$touch()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "red--text" }, [
                                  _vm._v(" *"),
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("comments")) + " "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.comments,
                          callback: function ($$v) {
                            _vm.comments = $$v
                          },
                          expression: "comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "px-4" : "" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        depressed: "",
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: {
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                        depressed: "",
                        disabled: _vm.disableUpload || _vm.uploading,
                      },
                      on: { click: _vm.uploadFiles },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("upload")) + " "),
                      _vm.uploading
                        ? _c("v-progress-circular", {
                            staticClass: "ml-2",
                            attrs: {
                              indeterminate: "",
                              size: "20",
                              color: "white",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.viewDialogVisible,
            callback: function ($$v) {
              _vm.viewDialogVisible = $$v
            },
            expression: "viewDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "" } },
                [
                  _c("v-toolbar-title", { staticClass: "text-left" }, [
                    _vm._v("View Image"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.downloadImage },
                    },
                    [_c("v-icon", [_vm._v("mdi-download")])],
                    1
                  ),
                ],
                1
              ),
              !_vm.viewImageloading
                ? _c("v-img", {
                    attrs: { src: _vm.dialogImageUrl, contain: "" },
                  })
                : _c(
                    "v-overlay",
                    { attrs: { value: true } },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.viewDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.bottmDialogVisible,
            callback: function ($$v) {
              _vm.bottmDialogVisible = $$v
            },
            expression: "bottmDialogVisible",
          },
        },
        [
          _c(
            "v-slide-y-transition",
            [
              _c(
                "v-card",
                { staticClass: "dialog-bottom pa-0" },
                [
                  _c("v-row", { staticClass: "ma-0 ml-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                      },
                      [_vm._v(_vm._s(_vm.$t("addImage")))]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [_c("v-divider")],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.openCamera } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-camera")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Open Camera"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { on: { click: _vm.selectFiles } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-folder-open")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Choose from Files"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.editDialogVisible,
            callback: function ($$v) {
              _vm.editDialogVisible = $$v
            },
            expression: "editDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-row", { staticClass: "ma-0 ml-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                  },
                  [_vm._v(_vm._s(_vm.$t("editItem")))]
                ),
              ]),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  class: _vm.captionError.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": _vm.editCaptionError.length === 0,
                      "error-messages": _vm.editCaptionError,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.editCaption.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.editCaption.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("caption")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.editCaption,
                      callback: function ($$v) {
                        _vm.editCaption = $$v
                      },
                      expression: "editCaption",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  class: _vm.commentError.length === 0 ? "mb-6" : "mb-0",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": _vm.editCommentError.length === 0,
                      "error-messages": _vm.editCommentError,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.editComment.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.editComment.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("comments")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.editComment,
                      callback: function ($$v) {
                        _vm.editComment = $$v
                      },
                      expression: "editComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        depressed: "",
                        width: "90",
                      },
                      on: {
                        click: function ($event) {
                          _vm.editDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: {
                        width: "90",
                        depressed: "",
                        disabled: _vm.disableUpdate,
                      },
                      on: { click: _vm.updateItem },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "600",
            scrollable: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.cameraDialog,
            callback: function ($$v) {
              _vm.cameraDialog = $$v
            },
            expression: "cameraDialog",
          },
        },
        [
          _c(
            "v-card",
            {},
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pr-2",
                              attrs: { icon: "", "aria-label": "close" },
                              on: { click: _vm.closeCamera },
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "text-h6 font-weight-medium" },
                            [_vm._v(_vm._s(_vm.$t("captureImage")))]
                          ),
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "px-0",
                        attrs: { cols: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-0", staticStyle: { height: "400px" } },
                [
                  _c("video", {
                    ref: "cameraView",
                    attrs: {
                      id: "player",
                      autoplay: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                  _c("canvas", {
                    attrs: { id: "canvas", width: "320px", height: "240px" },
                  }),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.captureImage } },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("mdi-camera-iris"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleteDialogVisible,
            callback: function ($$v) {
              _vm.deleteDialogVisible = $$v
            },
            expression: "deleteDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Delete Attachment")]),
              _c("v-divider"),
              _c("v-card-text", { staticClass: "py-2" }, [
                _vm._v(" Are you sure you want to delete this file? "),
              ]),
              _c(
                "v-card-actions",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "px-4" : "" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        outlined: "",
                        depressed: "",
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                      },
                      on: { click: _vm.closeDeleteDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: {
                        width: _vm.$vuetify.breakpoint.smAndDown ? "50%" : 90,
                        depressed: "",
                      },
                      on: { click: _vm.deleteMultipleUploadedFile },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }