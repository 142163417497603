<template>
  <div>
    <v-card
      color="grey lighten-4"
      v-if="$vuetify.breakpoint.mdAndUp"
      elevation="0"
      width="100%"
    >
      <v-row justify="center">
        <img
          class="mt-2 mb-1"
          src="@/assets/images/note_alt.svg"
          role="img"
          alt="note logo"
        />
      </v-row>
      <v-row justify="center" class="caption text--darken-4">
        <span
          class="mb-2 grey--text text--darken-4 text-subtitle-2"
          :class="notes.length > 0 ? 'mb-7' : 'mb-2'"
          >{{ notes.length == 0 ? $t("noNotesMsg") : "" }}</span
        >
      </v-row>
      <v-row justify="center">
        <v-btn
          color="primary"
          outlined
          class="mb-3 text-none rounded-lg"
          @click="openDialog"
          >{{ $t("addNote") }}</v-btn
        ></v-row
      >
    </v-card>
    <div class="dropbox ml-3 mr-3" v-if="$vuetify.breakpoint.smAndDown">
      <v-row no-gutters>
        <img src="@/assets/images/note_alt.svg" class="px-4 mt-2" />
        <v-col cols="9" class="mt-2">
          <span
            class="text-subtitle-2 font-weight-medium grey--text text--darken-4"
          >
            {{ $t("attachNotes") }}<br />
            <span
              style="font-size: 10px"
              class="font-weight-regular grey--text text--darken-2"
              >{{ notes.length == 0 ? $t("noNotesMsg") : "" }}</span
            >
          </span>
        </v-col>
        <v-col
          cols="9"
          class="mt-2 ml-16"
          :class="notes.length > 0 ? 'mt-3' : 'mt-2'"
        >
          <v-btn
            color="primary"
            outlined
            class="ml-3 mb-3 text-none rounded-sm"
            @click="openDialog"
            >{{ $t("addNote") }}</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <br />
    <div>
      <v-card v-if="notes.length > 0" elevation="0" width="100%">
        <v-container>
          <v-row class="ml-1">
            <span class="mt-1 grey--text text--darken-4 text-subtitle-2">{{
              $t("attachedNotes")
            }}</span>
          </v-row>
          <v-row class="ml-1">
            <span class="caption grey--text text--darken-2">{{
              notes.length + " " + $t("smNotes")
            }}</span>
          </v-row>
          <div v-for="(item, i) in notes" :key="i" class="mt-3">
            <v-row>
              <v-col cols="2" lg="1" md="1" xl="1" justify="center">
                <img
                  src="@/assets/images/note_rows_icon.svg"
                  role="img"
                  alt="note logo"
                />
              </v-col>
              <v-col cols="8" lg="10" md="10" xl="10" class="pl-0">
                <v-row
                  :class="$vuetify.breakpoint.smAndDown ? 'pl-2' : ''"
                  class="pt-3 caption text--black text--darken-4"
                >
                  {{ item.note }}
                </v-row>

                <v-row
                  :class="$vuetify.breakpoint.smAndDown ? 'pl-2' : ''"
                  class="pt-1 pb-4 caption grey--text grey-lighten-1"
                  >{{
                    moment(item.created_at).format("MM/DD/YYYY") +
                    " by " +
                    userMap[item.user_id].name
                  }}</v-row
                >
              </v-col>
              <v-col cols="2" lg="1" md="1" xl="1">
                <v-icon
                  @click="deleteNote(i)"
                  :class="
                    $vuetify.breakpoint.smAndDown ? 'pr-2 float-right' : ''
                  "
                  >mdi-delete-outline</v-icon
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </v-container>
      </v-card>
    </div>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      max-width="420"
      max-height="400"
      persistent
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("addNotes") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 ml-4 pt-4">
          <span class="text--darken-4 text-subtitle-1 font-weight-regular">Note</span>
        </v-row>
        <v-row class="ma-0 ml-4 mr-4 pt-3">
          <v-textarea
            rows="3"
            v-model="note"
            maxlength="4000"
            hide-details
            outlined
            dense
            required
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("enterNote") }}
            </template></v-textarea
          >
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            :disabled="!note || note == '' || addButtonClicked"
            @click="addNote"
          >
            {{ $t("add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notes: [],
      dialog: false,
      note: "",
      addButtonClicked: false,
    };
  },
  created() {
    this.moment = moment;
    this.notes =
      this.ticketDetails &&
      this.ticketDetails.notes &&
      this.ticketDetails.notes.length > 0
        ? this.ticketDetails.notes
        : [];
  },
  computed: {
    userMap() {
      return this.$store.state.User.companyUserMap;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.note = "";
    },
    openDialog() {
      this.dialog = true;
    },
    async addNote() {
      this.addButtonClicked = true;
      this.notes.push({
        note: this.note,
        created_at: new Date(),
        user_id: this.$store.state.User.user.user_id,
      });
      let obj = {
        notes: this.notes,
        ticket_id: this.ticketDetails.ticket_id,
      };
      const ticketStatus = await this.$store.dispatch("updateTicket", obj);
      if (ticketStatus.status != "error") {
        this.ticketDetails.notes = this.notes;
        this.closeDialog();
      }
      this.addButtonClicked = false;
    },
    async deleteNote(i) {
      let obj = {
        notes: [...this.notes],
        ticket_id: this.ticketDetails.ticket_id,
      };
      obj.notes.splice(i, 1);
      const ticketStatus = await this.$store.dispatch("updateTicket", obj);
      if (ticketStatus.status != "error") {
        this.notes.splice(i, 1);
        this.ticketDetails.notes = [...this.notes];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#note-text {
  font-size: 12px !important;
}
.dropbox {
  outline: 1px dashed grey;
  background: #f5f5f5;
  color: #323232;
  min-height: 75px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
</style>
