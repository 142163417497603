<template>
  <v-card color="grey lighten-5" elevation="0" width="100%">
    <v-row no-gutters class="pl-2 pt-2">
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        @click="showUploadDialog"
        outlined
        varient="plain"
        :disabled="limitExceeds"
      >
        <v-icon>mdi-upload</v-icon>
        Upload
      </v-btn>
      <v-alert
        v-if="uploadError"
        type="warning"
        color="#AD4C0B"
        icon="mdi-alert-outline"
        text
        class="caption font-weight-regular ml-2 py-2 px-5"
        height="38px"
        dismissible
      >
        Some error occured while uploading files.
      </v-alert>
      <v-alert
        v-if="limitExceeds"
        type="error"
        color="#AD4C0B"
        icon="mdi-alert-outline"
        text
        class="caption font-weight-regular ml-2 py-2 px-5"
        height="38px"
      >
        Storage limit reached
      </v-alert>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown && uploadedFiles.length"
        :disabled="selected.length === 0"
        @click="showDeleteDialog(false)"
        outlined
        varient="plain"
      >
        delete
      </v-btn>
    </v-row>
    <div v-if="uploadedFiles.length" class="pa-2">
      <v-data-table
        v-if="!$vuetify.breakpoint.smAndDown"
        :headers="dataTableHeaders"
        :items="uploadedFiles"
        hide-default-footer
        v-model="selected"
        show-select
        checkbox-color="primary"
        class="elevation-1"
        style="width: 100%"
      >
        <template v-slot:[`item.date`]="{ item }">
          <div class="nowrap">
            {{ formatTicketDate(item.date) }}
          </div>
        </template>
        <template v-slot:[`item.view`]="{ item }">
          <v-icon @click="showImageInDialog(item)" class="view-icon"
            >mdi-eye</v-icon
          >
        </template>
        <!-- Edit Button -->
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn @click="editItem(item)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon @click="showDeleteDialog(item.id, item)" class="delete-icon"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
      <div v-else class="mb-6">
        <!-- Select All Checkbox and Delete Button -->
        <v-row align="center" justify="space-between" class="pa-2">
          <v-col cols="6">
            <v-checkbox
              v-model="selectAll"
              label="Select All"
              @click="toggleSelectAll"
            />
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              varient="plain"
              @click="showDeleteDialog(false)"
              :disabled="selected.length === 0"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>

        <!-- Repeated Card-like layout for mobile view -->
        <div v-for="item in uploadedFiles" :key="item.id">
          <v-card class="my-2" elevation="0">
            <v-row no-gutters class="grey lighten-4 text--darken-4">
              <v-col cols="7" class="pa-2">
                <v-checkbox
                  v-model="selected"
                  class="mt-0 my-custom-checkbox"
                  hide-details
                  :value="item"
                >
                  <template v-slot:label
                    ><span class="font-weight-bold">{{
                      item.caption
                    }}</span></template
                  >
                </v-checkbox>
              </v-col>
              <v-col cols="5" class="text-right pa-2">
                <v-btn @click="showImageInDialog(item)" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn @click="editItem(item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="showDeleteDialog(item.id, item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-4">
              <!-- Left column - Labels -->
              <v-col cols="4" class="py-2">
                <div class="pb-2">Date:</div>
                <div class="pb-2">Comments:</div>
                <div class="pb-2">User Name:</div>
              </v-col>

              <!-- Right column - Values -->
              <v-col cols="8" class="py-2">
                <div class="font-weight-bold pb-2">
                  {{ formatTicketDate(item.date) }}
                </div>
                <div class="font-weight-bold pb-2">{{ item.comments }}</div>
                <div class="font-weight-bold pb-2">{{ item.userName }}</div>
              </v-col>
            </v-row>
            <!-- Card content here -->
          </v-card>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="height: 200px"
    >
      There are no images attached to this ticket
    </div>

    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="bottomNavbar"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
    >
      <v-row class="px-4 py-2">
        <v-btn outlined variant="plain" block @click="showUploadDialog">
          Upload <v-icon class="mr-0">mdi-upload</v-icon>
        </v-btn>
      </v-row>
    </v-bottom-navigation>
    <!-- Modal for uploading the image -->
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="600"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="">
        <v-card-title>{{ $t("uploadFiles") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px" class="px-0"
          ><div
            class="file-dropzone ma-2"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            @drop="handleDrop"
          >
            <div class="file-dropzone__content">
              <span>Drag and drop files here</span>
              <v-btn @click="onClickChoseFiles">Choose Files</v-btn>
            </div>
          </div>
          <v-row class="ma-2">
            <v-col cols="12">
              <div v-if="files.length" class="file-dropzone__selected">
                <v-data-table
                  :headers="headers"
                  :items="fileItems"
                  hide-default-footer
                  class="elevation-1"
                  style="width: 100%"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon @click="removeFile(item.file)" class="delete-icon"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <template v-slot:[`item.size`]="{ item }">
                    {{ formattedFileSize(item.file.size) }}
                  </template>
                </v-data-table>
              </div>
              <v-alert
                v-if="isError"
                type="error"
                icon="mdi-alert-outline"
                text
                class="caption font-weight-regular py-3 px-5"
                dismissible
                ><span
                  class="font-weight-bold grey--text text--darken-4"
                  v-if="unsupportedFile"
                  >{{ unsupportedFile }}</span
                >
                <div>{{ fileErrorMsg }}</div>
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mx-4 mt-4"
            :class="captionError.length === 0 ? 'mb-6' : 'mb-0'"
            justify="center"
          >
            <v-text-field
              v-model="caption"
              outlined
              dense
              required
              :hide-details="captionError.length === 0"
              :error-messages="captionError"
              @input="$v.caption.$touch()"
              @blur="$v.caption.$touch()"
              ><template v-slot:label>
                <span class="red--text"> *</span> {{ $t("caption") }}
              </template></v-text-field
            >
          </v-row>
          <v-row
            no-gutters
            class="mx-4 mt-4"
            :class="commentError.length === 0 ? 'mb-6' : 'mb-0'"
            justify="center"
          >
            <v-textarea
              v-model="comments"
              outlined
              auto-grow
              dense
              rows="3"
              row-height="25"
              required
              :hide-details="commentError.length === 0"
              :error-messages="commentError"
              @input="$v.comments.$touch()"
              @blur="$v.comments.$touch()"
              ><template v-slot:label>
                <span class="red--text"> *</span> {{ $t("comments") }}
              </template></v-textarea
            >
          </v-row></v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary text-none rounded-lg elevation-0"
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            depressed
            @click="uploadFiles"
            :disabled="disableUpload || uploading"
          >
            {{ $t("upload") }}
            <v-progress-circular
              v-if="uploading"
              indeterminate
              size="20"
              color="white"
              class="ml-2"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal for viewing the image -->
    <v-dialog v-model="viewDialogVisible" max-width="500px">
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title class="text-left">View Image</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="downloadImage" icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img v-if="!viewImageloading" :src="dialogImageUrl" contain></v-img>
        <v-overlay v-else :value="true">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="viewDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Bottom Dialog for choosing image in mobile view -->
    <v-dialog v-model="bottmDialogVisible">
      <v-slide-y-transition>
        <v-card class="dialog-bottom pa-0">
          <v-row class="ma-0 ml-4">
            <span
              class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
              >{{ $t("addImage") }}</span
            >
          </v-row>
          <v-row no-gutters>
            <v-divider></v-divider>
          </v-row>
          <v-card-text class="px-0">
            <v-list>
              <v-list-item @click="openCamera">
                <v-list-item-icon>
                  <v-icon>mdi-camera</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Open Camera</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="selectFiles">
                <v-list-item-icon>
                  <v-icon>mdi-folder-open</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Choose from Files</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-dialog>
    <!-- Dialog for editing item -->
    <v-dialog v-model="editDialogVisible" max-width="500px">
      <v-card>
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("editItem") }}</span
          >
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-4"
          :class="captionError.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="editCaption"
            outlined
            dense
            required
            :hide-details="editCaptionError.length === 0"
            :error-messages="editCaptionError"
            @input="$v.editCaption.$touch()"
            @blur="$v.editCaption.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("caption") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-4"
          :class="commentError.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="editComment"
            outlined
            dense
            required
            :hide-details="editCommentError.length === 0"
            :error-messages="editCommentError"
            @input="$v.editComment.$touch()"
            @blur="$v.editComment.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("comments") }}
            </template></v-text-field
          >
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="editDialogVisible = false"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="updateItem"
            :disabled="disableUpdate"
          >
            {{ $t("update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cameraDialog"
      max-width="480"
      max-height="600"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="">
        <v-card-title
          ><v-row>
            <v-col cols="9"
              ><v-btn icon aria-label="close" class="pr-2" @click="closeCamera">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span class="text-h6 font-weight-medium">{{
                $t("captureImage")
              }}</span></v-col
            >
            <v-col cols="3" class="px-0"> </v-col> </v-row
        ></v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px" class="px-0">
          <video
            id="player"
            ref="cameraView"
            autoplay
            width="100%"
            height="100%"
          ></video>
          <canvas id="canvas" width="320px" height="240px"></canvas>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="captureImage">
            <v-icon x-large>mdi-camera-iris</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialogVisible" max-width="500px">
      <v-card>
        <v-card-title>Delete Attachment</v-card-title>
        <v-divider></v-divider>

        <v-card-text class="py-2">
          Are you sure you want to delete this file?
        </v-card-text>

        <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            @click="closeDeleteDialog"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary text-none rounded-lg elevation-0"
            :width="$vuetify.breakpoint.smAndDown ? '50%' : 90"
            depressed
            @click="deleteMultipleUploadedFile"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatTicketDateTime } from "@/store/utils/utils.js";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { bus } from "@/main";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      caption: { required, maxLength: maxLength(30) },
      comments: { required, maxLength: maxLength(200) },
      editCaption: { required, maxLength: maxLength(30) },
      editComment: { required, maxLength: maxLength(200) },
    };
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
    ticketAttachments: {
      type: Array,
      default: () => [],
    },
    limitExceeds: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      files: [],
      bottomNavbar: true,
      showSnackbar: false,
      caption: "",
      comments: "",
      editComment: "",
      editCaption: "",
      headers: [
        { text: "File Name", value: "name", sortable: false },
        { text: "Size", value: "size", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Delete", value: "actions", sortable: false },
      ],
      dataTableHeaders: [
        { text: "Date", value: "date" },
        { text: "User Name", value: "userName" },
        { text: "Caption", value: "caption" },
        { text: "Comments", value: "comments" },
        { text: "View", value: "view", sortable: false },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      dialog: false,
      dialogImageUrl: "",
      downLoadImageUrl: "",
      attachments: [],
      selectedFiles: [],
      viewDialogVisible: false,
      bottmDialogVisible: false,
      editDialogVisible: false,
      selectedItemForEdit: {},
      selectedItemForView: {},
      selectedItemForDelete: null,
      selected: [],
      uploading: false,
      selectAll: false,
      // Maximum allowed total file size (16MB in bytes)
      maxTotalFileSize: 16 * 1024 * 1024,
      viewImageloading: false,
      cameraDialog: false,
      videoPlayer: null,
      mediaType: "image/jpeg",
      deleteDialogVisible: false,
      uploadError: false,
      isError: false,
      unsupportedFileTypes: [
        "gif",
        "mp4",
        "mp3",
        "avi",
        "mov",
        "exe",
        "msi",
        "bat",
      ],
      unsupportedFile: null,
    };
  },

  computed: {
    fileItems() {
      return this.files.map((file) => ({
        file,
        name: file.name,
        size: file.size,
        type: file.type,
      }));
    },
    captionError() {
      const errors = [];
      if (!this.$v.caption.$dirty) return errors;
      !this.$v.caption.required && errors.push(this.$t("captionRequired"));
      !this.$v.caption.maxLength && errors.push(this.$t("maxLenght40"));
      return errors;
    },
    commentError() {
      const errors = [];
      if (!this.$v.comments.$dirty) return errors;
      !this.$v.comments.required && errors.push(this.$t("commentRequired"));
      !this.$v.comments.maxLength && errors.push(this.$t("maxLength200"));
      return errors;
    },
    editCaptionError() {
      const errors = [];
      if (!this.$v.editCaption.$dirty) return errors;
      !this.$v.editCaption.required && errors.push(this.$t("captionRequired"));
      !this.$v.editCaption.maxLength && errors.push(this.$t("maxLenght40"));
      return errors;
    },
    editCommentError() {
      const errors = [];
      if (!this.$v.editComment.$dirty) return errors;
      !this.$v.editComment.required && errors.push(this.$t("commentRequired"));
      !this.$v.editComment.maxLength && errors.push(this.$t("maxLength200"));
      return errors;
    },
    disableUpload() {
      return (
        this.caption === "" ||
        this.captionError.length ||
        this.comments === "" ||
        this.commentError.length ||
        this.files.length === 0
      );
    },
    disableUpdate() {
      return (
        this.editCaption === "" ||
        this.editCaptionError.length !== 0 ||
        this.editComment === "" ||
        this.editCommentError.length !== 0
      );
    },
    uploadedFiles() {
      const files = this.ticketAttachments.attachments || [];
      return files.map((file) => {
        return {
          id: file.attachment_id,
          date: file.created_at,
          revision: "1.0",
          userName: this.getUserName(file.user_id),
          caption: file.caption,
          comments: file.comments,
          url: file.url,
          file: file.file,
        };
      });
    },
  },
  watch: {
    selected() {
      this.selectAll = this.selected.length === this.ticketAttachments.length;
    },
  },
  methods: {
    formattedFileSize(sizeInBytes) {
      if (sizeInBytes < 1024) {
        return sizeInBytes + " B";
      } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + " KB";
      } else {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      }
    },
    formatTicketDate(dateString) {
      return formatTicketDateTime(dateString);
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragLeave(event) {
      event.preventDefault();
    },
    onClickChoseFiles() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.bottmDialogVisible = true;
        return;
      }
      this.selectFiles();
    },
    handleDrop(event) {
      this.isError = false;
      this.unsupportedFile = null;
      const totalSize = Array.from(event.dataTransfer.files).reduce(
        (total, file) => total + file.size,
        0,
      );
      for (const file of event.dataTransfer.files) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (this.unsupportedFileTypes.includes(fileExtension)) {
          this.isError = true;
          this.fileErrorMsg = "Sorry, this file type not supported.";
          this.unsupportedFile = file.name;
          return;
        }
      }
      const combinedSize = totalSize + this.calculateTotalFileSize(this.files);

      // Check if the combined total size exceeds the limit
      if (combinedSize > this.maxTotalFileSize) {
        this.isError = true;
        this.fileErrorMsg = "Total file size exceeds the limit of 16MB.";
        return;
      }

      event.preventDefault();
      this.files = Array.from(event.dataTransfer.files);
    },
    selectFiles() {
      this.isError = false;
      this.unsupportedFile = null;
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*"; // Specify file types if needed
      input.multiple = true;
      input.onchange = (event) => {
        for (const file of event.target.files) {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          if (this.unsupportedFileTypes.includes(fileExtension)) {
            this.isError = true;
            this.fileErrorMsg = "Sorry, this file type not supported.";
            this.unsupportedFile = file.name;
            return;
          }
        }
        const totalSize = Array.from(event.target.files).reduce(
          (total, file) => total + file.size,
          0,
        );
        const combinedSize =
          totalSize + this.calculateTotalFileSize(this.files);

        // Check if the combined total size exceeds the limit
        if (combinedSize > this.maxTotalFileSize) {
          this.isError = true;
          this.fileErrorMsg = "Total file size exceeds the limit of 16MB.";
          this.bottmDialogVisible = false;
          return;
        }

        this.files = Array.from(event.target.files);
        this.bottmDialogVisible = false;
      };
      input.click();
    },
    calculateTotalFileSize(files) {
      return files.reduce((total, file) => total + file.size, 0);
    },
    removeFile(file) {
      this.files = this.files.filter((f) => f !== file);
    },
    editItem(item) {
      // Open the edit dialog and set the selected item for editing
      this.selectedItemForEdit = { ...item };
      this.editCaption = item.caption;
      this.editComment = item.comments; // Create a copy of the item to avoid modifying the original data
      this.editDialogVisible = true;
    },
    showUploadDialog() {
      this.dialog = true;
      this.uploadError = false;
    },
    closeDialog() {
      this.comments = "";
      this.caption = "";
      this.files = [];
      this.dialog = false;
      this.isError = false;
      this.unsupportedFile = null;
    },
    async updateItem() {
      const updateAttachment = await this.$store.dispatch("updateAttachment", {
        id: this.selectedItemForEdit.id,
        caption: this.editCaption,
        comments: this.editComment,
      });
      this.editDialogVisible = false;
      if (updateAttachment.status !== "error") {
        bus.$emit("updateAttachment", updateAttachment);
      } else {
        this.$emit("error");
      }
    },
    async uploadFiles() {
      this.uploading = true;
      const uploadedFileDetails = [];
      const tokenResp = await this.$store.dispatch(
        "getAuthTokenForAttachments",
      );
      let token;
      if (tokenResp.status !== "error") {
        token = tokenResp.token;
      } else if(tokenResp.message == 'Company storage limit reached') {
        this.limitExceeds = true;
      }
      try {
        for (const file of this.files) {
          const payload = {};
          payload.file = file;
          payload.companyId = this.$store.state.User.user.company_id;
          payload.token = token;
          const uploadFileStatus = await this.$store.dispatch(
            "uploadImages",
            payload,
          );
          if (uploadFileStatus.status !== "error") {
            uploadedFileDetails.push(uploadFileStatus[0]);
          } else {
            this.uploadError = true;
          }
        }
        if (uploadedFileDetails.length) {
          const payload = {
            attachments: uploadedFileDetails.map((item) => {
              return Object.assign({}, item, {
                company_id: this.$store.state.User.user.company_id,
                user_id: this.$store.state.User.user.user_id,
                reference_id: this.ticketDetails.ticket_id,
                reference_type: "ticket",
                caption: this.caption,
                comments: this.comments,
              });
            }),
          };
          const saveAttachments = await this.$store.dispatch(
            "saveAttachmentData",
            payload,
          );
          if (saveAttachments.status !== "error") {
            bus.$emit("filesUploded", saveAttachments);
          } else {
            console.log("error in saving attachments");
          }
        }
        this.files = [];
        this.uploadComplete = true;
        this.closeDialog();
        this.uploading = false;
      } catch (error) {
        this.uploadError = true;
      }
    },
    async showImageInDialog(item) {
      const attachmentServerUrl = process.env.VUE_APP_ATTACHMENTS_API;
      this.viewImageloading = true;
      this.viewDialogVisible = true;
      this.selectedItemForView = { ...item };
      const imageUrl = `${attachmentServerUrl}/download/inline/${encodeURIComponent(
        item.url,
      )}`;

      try {
        const response = await fetch(imageUrl, {
          method: "GET",
          headers: {
            accept: "binary/octet-stream",
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          this.dialogImageUrl = URL.createObjectURL(blob); // Set the downloaded image URL to the dialogImageUrl
          // Show the modal with the downloaded image
          this.viewImageloading = false;
        } else {
          console.error("Failed to download the image.");
        }
      } catch (error) {
        console.error("Network error. Failed to download the image.", error);
      }
    },
    showDeleteDialog(attachement_id) {
      this.deleteDialogVisible = true;
      if (attachement_id) {
        this.selected = [];
        this.selectedItemForDelete = attachement_id;
      }
    },
    async deleteMultipleUploadedFile() {
      let attachement_id;
      if (this.selectedItemForDelete) {
        attachement_id = [this.selectedItemForDelete];
      } else {
        attachement_id = this.selected.map((element) => {
          return element.id;
        });
      }

      let attachmentResp = await this.$store.dispatch("deleteAttachment", {
        attachmentIds: attachement_id,
      });
      if (attachmentResp.status !== "error") {
        bus.$emit("deleteAttachments", attachmentResp);
      }
      this.selected = [];
      this.selectedItemForDelete = null;
      this.closeDeleteDialog();
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
    },
    async downloadImage() {
      const downloadUrl = await this.getDownloadUrl();
      if (downloadUrl) {
        const response = await fetch(downloadUrl, {
          method: "GET",
          headers: {
            accept: "binary/octet-stream",
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          // Create a temporary URL for the downloaded image
          const imageUrl = URL.createObjectURL(blob);

          // Trigger the download
          const link = document.createElement("a");
          link.href = imageUrl;
          link.download = this.selectedItemForView.file;
          link.click();

          // Clean up the temporary URL
          URL.revokeObjectURL(imageUrl);
        } else {
          console.error("Failed to download the image.");
        }
      } else {
        console.error("Failed to get the download URL.");
      }
    },
    async getDownloadUrl() {
      const attachmentServerUrl = process.env.VUE_APP_ATTACHMENTS_API;
      const imageUrl = this.selectedItemForView.url;
      const endpoint = `${attachmentServerUrl}/download/attachment?url=${encodeURIComponent(
        imageUrl,
      )}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            accept: "binary/octet-stream",
          },
        });

        if (!response.ok) {
          console.error(
            "Failed to get the download URL. Server responded with status:",
            response.status,
          );
          return null;
        }

        try {
          const blob = await response.blob();
          const downloadUrl = URL.createObjectURL(blob);
          return downloadUrl;
        } catch (error) {
          console.error("Failed to read the binary response.", error);
          return null;
        }
      } catch (error) {
        console.error("Network error. Failed to get the download URL.", error);
        return null;
      }
    },
    getUserName(userId) {
      const companyUsers = this.$store.state.User.companyUsers;
      const user = companyUsers.find((user) => {
        return user.user_id === userId;
      });
      return user?.name;
    },
    openCamera() {
      this.cameraDialog = true;

      this.$nextTick(function () {
        this.videoPlayer = document.querySelector("#player");
        this.initializeMedia();
      });
    },
    closeCamera() {
      this.cameraDialog = false;
      this.videoPlayer.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
      });
      this.bottmDialogVisible = false;
    },
    toggleSelectAll() {
      this.selected = this.selectAll ? this.uploadedFiles : [];
    },
    initializeMedia() {
      if (!("mediaDevices" in navigator)) {
        navigator.mediaDevices = {};
      }

      if (!("getUserMedia" in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented!"),
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then((stream) => {
          this.videoPlayer.srcObject = stream;
          this.videoPlayer.style.display = "block";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    captureImage() {
      if (this.mediaType === "image/jpeg") {
        const canvas = document.createElement("canvas");
        canvas.width = this.$refs.cameraView.videoWidth;
        canvas.height = this.$refs.cameraView.videoHeight;
        canvas.getContext("2d").drawImage(this.$refs.cameraView, 0, 0);
        const capturedImage = this.dataURItoBlob(
          canvas.toDataURL("image/jpeg"),
        );
        this.files.push(capturedImage);
      }
      this.closeCamera();
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.file-dropzone__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.file-dropzone__selected {
  margin-top: 20px;
}

.delete-icon {
  cursor: pointer;
}
/* Custom CSS class for sliding dialog from the bottom */
.dialog-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff; /* Set the background color as needed */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for the bottom edge */
}
.caption-text {
  white-space: nowrap; /* Prevent caption from wrapping */
}
</style>
